import React, { useEffect, useState } from 'react';
import TableComponent from './component/maincontent.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';




const App = () => {
  const [backendData, setBackendData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.kt-endpoint.pro/api'); 
        const data = await response.json();
        setBackendData(data);
      } catch (error) {
        console.error('Gagal memuat data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <TableComponent data={backendData} />
    </div>
  );
}


export default App;