import React from 'react';

const MainTogelModal = ({ data }) => {
  
  return (
<div className="modal-dialog modal-lg responsive-modal p-0 w-100">
  <div className="modal-content p-0">
    <div className="modal-header text-center">
      <h2 className="modal-title m-auto mb-3 bg-danger rounded-3 p-1 text-white">{data.pasaran}: {data.date}</h2>
    </div>
    <div className="modal-body">
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>Pasaran</td>
            <td>{data.pasaran}</td>
          </tr>
          <tr>
            <td>BBFS</td>
            <td>{data.prediksi}</td>
          </tr>
          <tr>
            <td>Angka Main</td>
            <td>{data.angkamain}</td>
          </tr>
          <tr>
            <td>Colok Bebas</td>
            <td>{data.cb}</td>
          </tr>
          <tr>
            <td>Colok Macau</td>
            <td>{data.cm}</td>
          </tr>
          <tr>
            <td>SHIO</td>
            <td>{data.shio}</td>
          </tr>
          <tr>
            <td>Situs Result Resmi :</td>
            <td>{data.situs}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  );
};

export default MainTogelModal;


