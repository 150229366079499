import React, { useState } from 'react';
import Modal from 'react-modal';
import MainTogelModal from './maintogelmodal';
import Generateresult from './generateresult';



Modal.setAppElement('#root'); 

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%', 
      height: '70%',
      borderRadius: '20px'
      
    },

  };

  if (window.matchMedia('(max-width: 768px)').matches) {
    customStyles.content.width = '80%';
  }

  const MainContent = ({ data }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [result, setResult] = useState(null);
  
    function openModal(row) {
      setSelectedRow(row);
      setIsOpen(true);
    }
  
    function tutupModal() {
      setIsOpen(false);
    }
  
    const handleRedirect = () => {
      window.open('https://glhfds.com/kudus', '_blank');
    };

    const generateAngkaHoki = (digits) => {
      return Math.floor(Math.random() * Math.pow(10, digits));
    };
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    const handleGenerateResult = (digits) => {
      const newResult = generateAngkaHoki(digits);
      setResult(newResult);
    };

    const handleCopyResult = (text) => {
      copyToClipboard(text);
    };
  
    return (
      <div className="mt-5 mb-3">
        <div className="container">
          <div className="row">
            {data.map((row, index) => (
              <div key={index} className="col-md-6 col-sm-12">
                <div className="card mt-4">
                <div className="card-header d-flex align-items-center bg-danger text-white">
                  <marquee behavior="scroll" direction="left">{row.marqueeText}</marquee>
                </div>
                  <div className="card-body text-center">
                    <h3 className="card-title text-danger">{row.pasaran}</h3>
                    <h2 className="card-title fw-bold">- {row.prediksi} -</h2>
                    <p className="card-text text-danger">{row.date}</p>
                    <button className="btn btn-sm btn-warning" onClick={() => openModal(row)}>Prediksi</button>
                  </div>
                </div>
              </div>
            ))}

          </div>
          <div className="mt-5 text-center">
            <h5>Coba dapatkan Angka Hoki :</h5>

          </div>
          <div className="d-flex justify-content-center gap-3 mt-3">
          <button className="btn btn-sm btn-danger w-25" onClick={() => handleGenerateResult(5)}>5 Angka Hoki</button>
          <button className="btn btn-sm btn-danger w-25" onClick={() => handleGenerateResult(3)}>3 Angka Hoki</button>
          <button className="btn btn-sm btn-danger w-25" onClick={() => handleGenerateResult(2)}>2 Angka Hoki</button>
          </div>
          {result && (
                <Generateresult result={result} onCopy={handleCopyResult} />
            )}
        </div>
  
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={tutupModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {selectedRow && <MainTogelModal data={selectedRow} />}
          <div>
            <div className="d-flex justify-content-center gap-4">
              <button className="btn btn-sm btn-danger w-50" onClick={tutupModal}>Tutup</button>
              <button className="btn btn-sm btn-danger w-50" onClick={handleRedirect}>Lihat Result</button>
            </div>
            <p className="text-center mt-3">Update setiap hari dan paling akurat!  Situs togel terpercaya hanya <a className="text-decoration-none text-danger" href="https://glhfds.com/kudus">KUDUSTOTO</a></p>
            <p className="text-center mt-3">© 2015 - 2024 Copyright KUDUSTOTO. All Rights Reserved. </p>
          </div>
        </Modal>
        {copied && <div className="position-fixed top-0 start-50 translate-middle-x bg-success text-white p-2 rounded mt-5">Berhasil di salin !</div>}
      </div>
    );
  };
  
  export default MainContent;
